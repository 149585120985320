import { render, staticRenderFns } from "./CategoryCreate.vue?vue&type=template&id=41d64eab&"
import script from "./CategoryCreate.vue?vue&type=script&lang=js&"
export * from "./CategoryCreate.vue?vue&type=script&lang=js&"
import style0 from "./CategoryCreate.vue?vue&type=style&index=0&id=41d64eab&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports