<template>
  <div id="category-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#EDBE38"
              >Refresh</v-btn>
              <br />
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Category Name</h5>
              <p class="preview-content">{{ row.CategoryName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Category Field Name</h5>
              <p class="preview-content">{{ row.CategoryFieldName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Category Description</h5>
              <p class="preview-content">{{ row.CategoryDescription }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Category Status</h5>
              <v-chip
                v-if="row.ActiveStatusTxt != undefined && row.ActiveStatusTxt != ''"
                :color="getActiveStatusColor(row.ActiveStatusTxt)"
                draggable
                dark
              >{{ row.ActiveStatusTxt }}</v-chip>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Category Sub List</h3>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData1.length > 0">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
            <v-col align="center" cols="12" md="12" v-else>
              <h4>No Records found.</h4>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData1.length > 0">
              <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>-->
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                  >{{ item.ActiveStatusTxt }}</v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    EditRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      type: Array,
      required: true
    },
    PageTitle: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      AddRecordPrompt: false,
      EditRecordPrompt: false,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",

      row: {},

      PageInfo: {},
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;

      this.CategoryId = this.recordData.CategoryId;

      this.getTableRecords();
      this.getCategoryDetails();
    },
    getCategoryDetails() {
      console.log("getCategoryDetails is called");
      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.row = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/category/show";
      var upload = {
        UserInterface: 1,
        Category: this.CategoryId
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          console.log({ records });

          if (flag == 1) {
            thisIns.row = records;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
    getTableRecords() {
      console.log("getTableRecords is called");
      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/category-sub/lists";
      var upload = {
        UserInterface: 2,
        Category: this.CategoryId
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          console.log({ records });

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions1 = options;

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#category-edit {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>